var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row justify-content-start" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              placeholder: "Unidades",
                              searchable: false,
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsUnits,
                            },
                            model: {
                              value: _vm.filters.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "unit", $$v)
                              },
                              expression: "filters.unit",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              searchable: false,
                              placeholder: "Status",
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.filters.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "status", $$v)
                              },
                              expression: "filters.status",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Período",
                            config: {
                              mode: "range",
                              altInput: true,
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          model: {
                            value: _vm.filters.rangeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "rangeDate", $$v)
                            },
                            expression: "filters.rangeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm.submitedFilter
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { label: "Loading...", small: "" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "SearchIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.submitedFilter
        ? _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-form-row",
                    { staticClass: "justify-content-between" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-none d-sm-block align-items-center justify-content-left",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "title",
                              required: "",
                              options: _vm.perPageOptions,
                              clearable: false,
                            },
                            on: { input: _vm.setPerPageSelected },
                            model: {
                              value: _vm.filters.perPage,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "perPage", $$v)
                              },
                              expression: "filters.perPage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4", offset: "md-4" } },
                        [
                          _c("b-form-input", {
                            staticClass: "d-inline-block",
                            attrs: {
                              type: "search",
                              placeholder: "Pesquisar...",
                              id: "filterInput",
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$can("Repasse - Criar", "Comercial")
                    ? _c(
                        "b-form-row",
                        { staticClass: "mt-2 justify-content-end" },
                        [
                          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-primary btn-block",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.selectedItems.length < 1 ? true : false,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCreateModal()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "bi bi-file-earmark-plus cursor-pointer cursor",
                                  staticStyle: {
                                    "font-size": "1.2rem",
                                    "line-height": "1.5",
                                    "margin-right": "5px",
                                  },
                                }),
                                _vm._v(" Criar Repasse "),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  items: _vm.dataItems,
                  responsive: "",
                  fields: _vm.fields,
                  "sticky-header": "",
                  "primary-key": "id",
                  "show-empty": "",
                  "empty-text": "Nenhum registro encontrado",
                  filter: _vm.filter,
                  "filter-included-fields": _vm.filterOn,
                },
                on: { filtered: _vm.onFiltered },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(select)",
                      fn: function () {
                        return [
                          _c("b-form-checkbox", {
                            on: { change: _vm.toggleSelectAll },
                            model: {
                              value: _vm.selectAllCheckbox,
                              callback: function ($$v) {
                                _vm.selectAllCheckbox = $$v
                              },
                              expression: "selectAllCheckbox",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(select)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("b-form-checkbox", {
                            staticStyle: {
                              "margin-left": "13px",
                              padding: "0px",
                              width: "20px",
                              margin: "auto",
                            },
                            attrs: { value: item.id },
                            model: {
                              value: _vm.selectedItems,
                              callback: function ($$v) {
                                _vm.selectedItems = $$v
                              },
                              expression: "selectedItems",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell(customer_name)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-media",
                            { attrs: { "vertical-align": "center" } },
                            [
                              _c(
                                "span",
                                { staticClass: "d-block text-nowrap" },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.customer_name) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c("strong", [
                                    _c("i", {
                                      staticClass: "bi bi-credit-card-2-front",
                                    }),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(data.item.customer_cpf) + " - "
                                  ),
                                  _c(
                                    "strong",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top",
                                          value:
                                            "Quantidade de produtos na matrícula.",
                                          expression:
                                            "'Quantidade de produtos na matrícula.'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "text-purple",
                                    },
                                    [
                                      _c("i", { staticClass: "bi bi-box" }),
                                      _vm._v(
                                        " " + _vm._s(data.item.items) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(payment_method)",
                      fn: function (data) {
                        return [
                          _c("i", {
                            class: "bi " + data.item.payment_method_icon,
                            staticStyle: { "font-size": "1.3rem" },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell(status_name)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-badge",
                            {
                              staticClass: "d-block",
                              attrs: {
                                variant: "light-" + data.item.status_class,
                              },
                            },
                            [_vm._v(" " + _vm._s(data.item.status_name) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(unit_name)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("div", { staticClass: "d-grid-center" }, [
                            _c("strong", [
                              _vm._v(" " + _vm._s(item.unit_name) + " "),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(total)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toCurrency")(data.item.total)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(action)",
                      fn: function (data) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                id: "product-" + data.item.id + "-preview-icon",
                                to: {
                                  name: "transactions-orders-preview",
                                  params: { uuid: data.item.uuid },
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer cursor",
                                attrs: { icon: "EyeIcon", size: "16" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3313542864
                ),
              }),
              _c("CustomPaginateTable", {
                attrs: {
                  "rows-table": _vm.rowsTable,
                  "current-page": _vm.filters.currentPage,
                  "per-page": _vm.filters.perPage,
                  "total-rows": _vm.totalRows,
                },
              }),
            ],
            1
          )
        : _c("b-card", { staticClass: "mb-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12 text-center" }, [
                _c(
                  "p",
                  { staticClass: "my-0 py-3 d-flex-center" },
                  [
                    _c("b-spinner", {
                      staticClass: "mr-50",
                      attrs: { small: "", variant: "primary" },
                    }),
                    _vm._v(" Buscando matrículas... "),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-create-remittance",
            title: "Anexar Arquivos",
            "hide-footer": "",
            centered: "",
          },
        },
        [
          _c(
            "div",
            [
              _c("p", [
                _vm._v(" Você selecionou "),
                _c("strong", [_vm._v(_vm._s(_vm.selectedItems.length))]),
                _vm._v(" itens. "),
              ]),
              _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("flat-pickr", {
                        attrs: {
                          placeholder: "Dia do Repasse",
                          config: {
                            mode: "single",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.remittance_date,
                          callback: function ($$v) {
                            _vm.remittance_date = $$v
                          },
                          expression: "remittance_date",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "mb-0" }, [
                    _c("div", [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-10" },
                          [
                            _c("b-card-title", { staticClass: "mb-1" }, [
                              _vm._v(" Arquivos "),
                            ]),
                            _c("b-card-sub-title", [
                              _vm._v(
                                " Você pode adicionar 1 ou mais arquivos relacionado a este pedido de compra. "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block btn-primary",
                              staticStyle: {
                                "padding-left": "5px !important",
                                "padding-right": "5px !important",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectFiles.apply(null, arguments)
                                },
                              },
                            },
                            [_c("i", { staticClass: "bi bi-plus-circle" })]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("b-card-body", { staticClass: "p-2" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("input", {
                            ref: "fileInput",
                            staticClass: "form-control-file hidden",
                            attrs: {
                              type: "file",
                              multiple: "",
                              accept:
                                ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                            },
                            on: { change: _vm.previewDocs },
                          }),
                          !_vm.files.length
                            ? _c(
                                "p",
                                {
                                  staticClass: "py-3 text-center mb-0",
                                  staticStyle: {
                                    border: "1px solid rgb(222 222 222)",
                                    "border-radius": "4px",
                                    "border-style": "dashed",
                                    "font-size": "1.1rem",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-file-earmark-text",
                                  }),
                                  _vm._v(" Selecionar arquivos "),
                                  _c(
                                    "small",
                                    { staticClass: "text-center mt-1 d-block" },
                                    [
                                      _vm._v(
                                        " Selcione apenas arquivos nos formatos: "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "label",
                            { staticClass: "d-block text-center mb-0" },
                            [
                              _vm.files.length
                                ? _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-group list-group-light",
                                      attrs: { id: "array-files" },
                                    },
                                    _vm._l(_vm.files, function (doc, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass:
                                            "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-9 text-left",
                                                  staticStyle: {
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(doc.name) + " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-2 text-center",
                                                  staticStyle: {
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("bytesToMB")(
                                                          doc.size,
                                                          2
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-1 text-center",
                                                  staticStyle: {
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-danger btn-sm",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.deleteFile(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-trash3",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end mt-3" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        variant: "success",
                        disabled:
                          _vm.selectedItems.length === 0 ||
                          _vm.submitted ||
                          !_vm.remittance_date,
                      },
                      on: { click: _vm.confirmRepasse },
                    },
                    [_vm._v(" Confirmar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }