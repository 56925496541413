<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row justify-content-start">
          <b-col cols="12" md="4">
            <div class="form-group">
              <v-select
                label="title"
                placeholder="Unidades"
                :searchable="false"
                item-text="title"
                item-value="code"
                :options="optionsUnits"
                v-model="filters.unit"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :searchable="false"
                placeholder="Status"
                :options="optionsStatus"
                v-model="filters.status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="form-group mb-md-0">
              <!-- Um dia é o default -->
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                placeholder="Período"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>

          <b-col cols="12" md="1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body v-if="!submitedFilter" class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
        <b-form-row
          class="mt-2 justify-content-end"
          v-if="$can('Repasse - Criar', 'Comercial')"
        >
          <b-col cols="12" md="3">
            <button
              type="button"
              class="btn btn-sm btn-primary btn-block"
              @click="openCreateModal()"
              :disabled="selectedItems.length < 1 ? true : false"
            >
              <i
                style="font-size: 1.2rem; line-height: 1.5; margin-right: 5px"
                class="bi bi-file-earmark-plus cursor-pointer cursor"
              ></i>
              Criar Repasse
            </button>
          </b-col>
        </b-form-row>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        sticky-header
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #head(select)>
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.id"
            v-model="selectedItems"
          />
        </template>

        <template #cell(customer_name)="data">
          <b-media vertical-align="center">
            <span class="d-block text-nowrap">
              {{ data.item.customer_name }}
            </span>
            <p class="mb-0" style="margin-top: 4px">
              <strong><i class="bi bi-credit-card-2-front"></i></strong>
              {{ data.item.customer_cpf }} -
              <strong
                class="text-purple"
                v-b-tooltip.hover.top="'Quantidade de produtos na matrícula.'"
              >
                <i class="bi bi-box"></i> {{ data.item.items }}
              </strong>
            </p>
          </b-media>
        </template>

        <template #cell(payment_method)="data">
          <i
            style="font-size: 1.3rem"
            :class="`bi ${data.item.payment_method_icon}`"
          ></i>
        </template>

        <template #cell(status_name)="data">
          <b-badge class="d-block" :variant="`light-${data.item.status_class}`">
            {{ data.item.status_name }}
          </b-badge>
        </template>

        <template #cell(unit_name)="{ item }">
          <div class="d-grid-center">
            <strong>
              {{ item.unit_name }}
            </strong>
          </div>
        </template>

        <template #cell(total)="data">
          {{ data.item.total | toCurrency }}
        </template>

        <template #cell(action)="data">
          <router-link
            :id="`product-${data.item.id}-preview-icon`"
            :to="{
              name: 'transactions-orders-preview',
              params: { uuid: data.item.uuid },
            }"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="cursor-pointer cursor"
            />
          </router-link>
        </template>
      </b-table>
      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-card v-else class="mb-0">
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="my-0 py-3 d-flex-center">
            <b-spinner small variant="primary" class="mr-50" /> Buscando
            matrículas...
          </p>
        </div>
      </div>
    </b-card>

    <!-- Modal para Cadastrar -->
    <b-modal
      id="modal-create-remittance"
      title="Anexar Arquivos"
      hide-footer
      centered
    >
      <div>
        <p>
          Você selecionou <strong>{{ selectedItems.length }}</strong> itens.
        </p>
        <b-card no-body class="mb-1">
          <div class="form-row">
            <div class="col-md-12">
              <flat-pickr
                v-model="remittance_date"
                placeholder="Dia do Repasse"
                :config="{
                  mode: 'single',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
        </b-card>
        <!-- Upload de Arquivos Múltiplos -->
        <b-card no-body class="mb-1">
          <b-card-header class="mb-0">
            <div>
              <div class="form-row">
                <div class="col-md-10">
                  <b-card-title class="mb-1"> Arquivos </b-card-title>
                  <b-card-sub-title>
                    Você pode adicionar 1 ou mais arquivos relacionado a este
                    pedido de compra.
                  </b-card-sub-title>
                </div>
                <div class="col-md-2">
                  <button
                    @click.prevent="selectFiles"
                    type="button"
                    class="btn btn-block btn-primary"
                    style="
                      padding-left: 5px !important;
                      padding-right: 5px !important;
                    "
                  >
                    <i class="bi bi-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </b-card-header>
          <b-card-body class="p-2">
            <div class="form-row">
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <input
                    ref="fileInput"
                    type="file"
                    multiple
                    class="form-control-file hidden"
                    @change="previewDocs"
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  />

                  <p
                    v-if="!files.length"
                    class="py-3 text-center mb-0"
                    style="
                      border: 1px solid rgb(222 222 222);
                      border-radius: 4px;
                      border-style: dashed;
                      font-size: 1.1rem;
                    "
                  >
                    <i class="bi bi-file-earmark-text"></i>
                    Selecionar arquivos
                    <small class="text-center mt-1 d-block">
                      Selcione apenas arquivos nos formatos: <br />
                      xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf
                    </small>
                  </p>

                  <label class="d-block text-center mb-0">
                    <ul
                      class="list-group list-group-light"
                      id="array-files"
                      v-if="files.length"
                    >
                      <li
                        class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
                        v-for="(doc, index) in files"
                        :key="index"
                      >
                        <div class="form-row">
                          <div
                            class="col-md-9 text-left"
                            style="font-size: 0.9rem"
                          >
                            {{ doc.name }}
                          </div>
                          <div
                            class="col-md-2 text-center"
                            style="font-size: 0.9rem"
                          >
                            {{ doc.size | bytesToMB(2) }}
                          </div>
                          <div
                            class="col-md-1 text-center"
                            style="font-size: 0.9rem"
                          >
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              @click.prevent="deleteFile(index)"
                            >
                              <i class="bi bi-trash3"></i>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </label>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>

        <!-- Botões de Ação -->
        <div class="d-flex justify-content-end mt-3">
          <b-button variant="secondary" @click="closeModal">Cancelar</b-button>
          <b-button
            variant="success"
            class="ml-1"
            :disabled="
              selectedItems.length === 0 || submitted || !remittance_date
            "
            @click="confirmRepasse"
          >
            Confirmar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  VBTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BFormCheckbox,
  BModal,
  BFormFile,
  BFormGroup,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BBadge,
    BFormCheckbox,
    BCardBody,
    vSelect,
    BModal,
    BFormFile,
    BFormGroup,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
  },
  data() {
    return {
      submitted: false,
      fileInput: "",
      files: [],
      selectedItems: [],
      filters: {
        rangeDate: `${moment()
          .subtract("5", "week")
          .format("YYYY-MM-DD")} até ${moment().format("YYYY-MM-DD")}`,
        status: "",
        currentPage: 1,
        perPage: 100,
      },
      selectAllCheckbox: false,
      submitedFilter: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      remittance_date: null,
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "id",
          label: "ID",
          class: "text-left",
        },
        {
          key: "customer_name",
          label: "Cliente",
          class: "text-left",
        },
        {
          key: "unit_name",
          label: "Unidade",
          class: "text-center",
        },
        {
          key: "status_name",
          label: "Status",
          class: "text-center",
        },
        {
          key: "payment_method",
          label: "Forma",
          class: "text-center",
        },
        {
          key: "total",
          label: "total",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      optionsStatus: [],
      optionsUnits: [],
    };
  },
  validations: {
    remittance_date: { required },
  },
  methods: {
    openCreateModal() {
      this.$bvModal.show("modal-create-remittance");
    },
    closeModal() {
      this.$bvModal.hide("modal-create-remittance");
      this.submitted = false;
      this.clearPayload();
    },
    checkSelectedItems() {
      if (this.selectedItems.length === 0) {
        this.$bvToast.toast("Selecione ao menos um item!", {
          title: "Aviso",
          variant: "warning",
          solid: true,
        });
        return;
      }
    },
    clearPayload() {
      this.selectedItems = [];
      this.selectAllCheckbox = false;
      this.files = [];
      this.remittance_date = null;
    },
    confirmRepasse() {
      this.checkSelectedItems();
      this.submitted = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$swal({
          title: "Confirmar a criação?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-light ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let formData = new FormData();

            this.selectedItems.forEach((id, index) => {
              formData.append(`items[${index}]`, id);
            });

            for (var index = 0; index < this.files.length; index++) {
              formData.append("files[]", this.files[index]);
            }

            formData.append("remittance_date", this.remittance_date);

            this.$store
              .dispatch("Order/createRemittance", formData)
              .then(() => {
                this.closeModal();
                this.notifyDefault("success");
                this.getData();
              });
          }
        });
      }
    },
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems.map((item) => item.id)
        : [];
    },
    async fetchUnits() {
      this.optionsUnits = await this.$store.dispatch("Unit/forSelect");
    },
    async fetchStatus() {
      this.$store.dispatch("Order/status").then((res) => {
        this.optionsStatus = res;
      });
    },
    submitFilter() {
      this.getData();
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    selectFiles() {
      this.$refs.fileInput.click();
    },
    previewDocs(event) {
      if (event.target.files.length) {
        this.files = [...this.files, ...event.target.files];
        this.$refs.fileInput.value = "";
      }
    },
    deleteFile(index) {
      this.files.splice(index, 1);
      this.$refs.fileInput.value = "";
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        status: this.filters.status ? this.filters.status.code : "",
        unit: this.filters.unit ? this.filters.unit.code : "",
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
        remittance: 0,
      };

      this.$store
        .dispatch("Order/allEnrollments", filtersSearch)
        .then((orders) => {
          if (orders) {
            this.dataItems = orders.data;
            this.rowsTable = orders.data.length;
            this.totalRows = orders.meta.total;
            this.filters.currentPage = orders.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  async mounted() {
    this.getData();
    this.fetchStatus();
    this.fetchUnits();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar Matrículas",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de matrículas.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("Order/enrollmentsExport", {
              filters: {
                rangeDate: this.filters.rangeDate ?? "",
                status: this.filters.status ? this.filters.status.code : "",
                method: this.filters.method ? this.filters.method.code : "",
                classroom: this.filters.classroom
                  ? this.filters.classroom.code
                  : "",
                course: this.filters.course ? this.filters.course.code : "",
                cpf: this.filters.cpf ?? "",
                id: this.filters.id ?? "",
                unit: this.filters.unit ? this.filters.unit.code : "",
              },
            })
            .then((res) => {
              console.log(res);
            })
            .finally(() => {
              this.submitedFilter = false;
            });
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
